<template>
    <div class="blog">
        <div class="is-flex is-justify-content-center">
            <b-image class="cover--img "
                placeholder="https://img.freepik.com/free-vector/white-blurred-background_1034-249.jpg?w=1800&t=st=1708442358~exp=1708442958~hmac=5891eb777b4ebf18a6a381b236b84b6c687485822f877ca1de3e35d56f82ea1c"
                src="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-060-65467351c0fae-65467e5a10b62.webp?alt=media&token=61421dc5-d2cb-4141-b254-80ceb4e339e0">

            </b-image>

        </div>

        <div class=" container is-max-desktop">
            <div class="is-flex is-align-items-center is-justify-content-center mb-2">
                <img class="image--rounded"
                    src="https://images.pexels.com/photos/8163130/pexels-photo-8163130.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />


            </div>

            <h1 class="has-text-weight-bold is-size-3 mb-4 is-justify-content-center is-flex">Best things to see and do in Sintra in a day.</h1>
            
            <SocialSharing
            url="https://guiders/blog/best-things-to-see-and-do-in-sintra-in-a-day"
            title="Best things to see and do in Sintra in a day."
            image="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-060-65467351c0fae-65467e5a10b62.webp?alt=media&token=61421dc5-d2cb-4141-b254-80ceb4e339e0"
            />
            
            <p class="mb-4">If you have some extra time during your stay in Lisbon, We highly recommend taking a day
                trip to
                the beautiful town of Sintra. Just a train ride away from the city, Sintra is a magical place filled
                with
                stunning architecture and breathtaking gardens.</p>

            <p class="mb-2">Here are some of the top attractions that you shouldn't miss during your visit to Sintra:
            </p>

            <section>
                <h3 class="has-text-weight-bold is-size-4 mt-6 mb-5">#1. Quinta da Regaleira</h3>
                <div class="mb-3">
                    <img class="topic--img"
                        src="https://images.unsplash.com/photo-1627841216554-747d2fb17385?q=80&w=2711&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <figcaption>Photo by <a
                            href="https://unsplash.com/@777s?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">777
                            S</a> on <a
                            href="https://unsplash.com/photos/grey-concrete-building-under-blue-sky-during-daytime-7sVkainAbpg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                    </figcaption>
                </div>


                <p class="mb-4">Quinta da Regaleira is a fascinating estate that should not be missed during your visit to
                    Sintra. This enchanting property is known for its intriguing architecture, beautiful gardens, and
                    mysterious features.
                </p>
                <b-collapse :open="false" position="is-bottom" aria-id="contentIdForA11y1">

                    <template #trigger="props">
                        <div>
                            <b-button  type="is-outlined" class="outlined-button" v-show="props.open" >
                                 Read less
                            </b-button>
                            <b-button  type="is-outlined" class="outlined-button" v-show="!props.open" >
                                 Read more
                            </b-button>
                        </div>
                    </template>
                    <div>
                        <p class="mb-4">As you explore the grounds, you'll come across hidden tunnels, secret passages, and
                            symbolic
                            wells, including the famous Initiation Well. Descending into the depths of the Initiation Well
                            is a
                            unique experience that adds to the sense of mystery and adventure.
                        </p>
                        <p class="mb-4">The estate also features stunning buildings adorned with intricate details and
                            stunning
                            facades. Take your time to admire the impressive architecture and soak in the magical
                            atmosphere.</p>
                        <p class="mb-4">Visiting Quinta da Regaleira is like stepping into a fairy tale. It offers a sense
                            of wonder
                            and discovery that will leave you captivated. Don't forget to bring your camera to capture the
                            beauty of
                            this remarkable place.</p>
                    </div>
                </b-collapse>

                <div>
                    <p class="mt-5 mb-5 has-text-weight-semibold">Ways to experience <b class="highlight">Quinta da
                            Regaleira</b></p>
                    <TicketsAndActivitiesContainer class="mt-3" :is-full-width="false" :Experiences="['D1YH6p0pVThgornEsYhe']"
                        :Tickets="['gCXGmPJx4oEwEONrGjjp', '2VJ3888cMamYBoscKaCN']" />

                </div>

            </section>

            <section>
                <h3 class="has-text-weight-bold is-size-4 mt-6 mb-5">#2. Pena Palace</h3>

                <div class="mb-3">
                    <img class="topic--img"
                        src="https://images.unsplash.com/photo-1592862546334-f958506f9631?q=80&w=3833&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <figcaption>Photo by <a
                            href="https://unsplash.com/@mark_lawson?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Mark
                            Lawson</a> on <a
                            href="https://unsplash.com/photos/people-walking-on-street-near-buildings-during-daytime-Y0KQMF2_QL8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                    </figcaption>
                </div>

                <p class="mb-4">Pena Palace is one of the must-visit attractions in Sintra. This colorful palace sits atop a
                    hill, offering
                    panoramic views of the surrounding countryside. Its unique blend of architectural styles makes it truly
                    one-of-a-kind.
                </p>

                <b-collapse :open="false" position="is-bottom" aria-id="contentIdForA11y1">

                    <template #trigger="props">
                        <div>
                            <b-button  type="is-outlined" class="outlined-button" v-show="props.open" >
                                 Read less
                            </b-button>
                            <b-button  type="is-outlined" class="outlined-button" v-show="!props.open" >
                                 Read more
                            </b-button>
                        </div>
                    </template>
                    <div>
                        <p class="mb-4">The palace features vibrant colors, intricate detailing, and whimsical turrets,
                            creating a
                            fairytale-like
                            atmosphere. As you explore the palace grounds, you'll come across beautiful gardens, hidden
                            pathways,
                            and
                            stunning viewpoints. The interior of the palace is equally captivating, with opulent rooms
                            showcasing a
                            rich
                            history.
                        </p>
                        <p class="mb-4">Make sure to take your time to soak in the beauty of Pena Palace and appreciate the
                            stunning
                            views it offers.
                            It's a remarkable place that will transport you to another world.</p>

                    </div>
                </b-collapse>
                <div>
                    <p class="mt-5 mb-5 has-text-weight-semibold">Ways to experience <b class="highlight">Pena Palace</b></p>
                    <TicketsAndActivitiesContainer class="mt-3" :is-full-width="false" :Experiences="['D1YH6p0pVThgornEsYhe']"
                        :Tickets="['8VYdIS0QYTK5e8CkBJIy', 'WvuU8qifxuue5GWWZvKJ', 'ixYr8kPsYErpaPuj4cDX']" />
                </div>
            </section>

            <section>
                <h3 class="has-text-weight-bold is-size-4 mt-6 mb-5">#3. Sintra National Palace</h3>

                <div class="mb-3">
                    <img class="topic--img"
                        src="https://embracesomeplace.com/wp-content/uploads/2023/01/Sintra-Portugal-03087.jpg" />

                </div>

                <p class="mb-4">Located in the heart of the town, Sintra National Palace is a must-visit attraction. This
                    historic palace is known for its distinctive twin chimneys and is a symbol of Portugal's rich royal
                    history. Step inside and be amazed by the beautifully decorated rooms, including the stunning Sala dos
                    Brasões (Coat of Arms Room) and the Sala dos Cisnes (Swan Room). </p>



                <b-collapse :open="false" position="is-bottom" aria-id="contentIdForA11y1">

                    <template #trigger="props">
                        <div>
                            <b-button  type="is-outlined" class="outlined-button" v-show="props.open" >
                                 Read less
                            </b-button>
                            <b-button  type="is-outlined" class="outlined-button" v-show="!props.open" >
                                 Read more
                            </b-button>
                        </div>
                    </template>
                    <div>
                        <p class="mb-4">As you explore the palace, you'll learn about the fascinating stories of Portuguese
                            kings
                            and queens who once resided here. Don't miss the opportunity to admire the intricate tilework
                            and
                            intricate ceilings that add to the palace's grandeur.</p>
                    </div>
                </b-collapse>
            </section>

            <section>
                <h3 class="has-text-weight-bold is-size-4 mt-6 mb-5">How to Get to Sintra from Lisbon</h3>

                <p class="mb-4">To get to Sintra from Lisbon, simply take a train from Rossio Station in the city center.
                    Trains run frequently throughout the day, and the journey takes approximately 40 minutes. Once you
                    arrive at Sintra Station, you can either walk or take a bus/taxi to reach the town center.</p>


            </section>

            <section>
                <h3 class="has-text-weight-bold is-size-4 mt-6 mb-5">Insider Tip: Beat the Crowds</h3>

                <p class="mb-4">Sintra is undoubtedly a popular tourist destination, and it can get quite crowded,
                    especially during peak season. If possible, try to visit on a weekday and arrive early in the morning to
                    avoid long queues and enjoy a more peaceful experience.
                </p>
                <p class="mb-4">So, if you're looking to escape the bustling city and immerse yourself in a fairytale
                    setting, don't miss the opportunity to visit Sintra. Trust us, it's worth every minute!
                </p>

            </section>
        </div>
    </div>
</template>

<script>
import SocialSharing from '@/Blog/SocialSharing'
import TicketsAndActivitiesContainer from "../components/TicketsAndActivitiesContainer.vue";
import { mapActions, mapState } from "vuex";
import { TicketsActions, TicketsStates, ActivitiesActions } from "../store/Storetypes";

export default {
    components: { TicketsAndActivitiesContainer, SocialSharing },
    methods: {
        ...mapActions({
            getTickets: TicketsActions.GET_TICKETS,
            getActivitiesById: ActivitiesActions.GET_ACTIVITY_BY_IDS
        }),

        prepareTickets(ticketIds) {
            if (!this.tickets.length) {
                this.getTickets()
            }
            return this.tickets.filter((ticket) => ticketIds.includes(ticket.id))
        },
        async prepareActivities(ids) {
            const activities = await this.getActivitiesById(ids)
            return activities
        },
    },
    metaInfo() {
    return {
      title: "Best things to see and do in Sintra in a day.",
      content: `Are you ready to embark on a whirlwind adventure through the enchanting town of Sintra? Look no further than our comprehensive guide to exploring Sintra in just one day. Dive into a world of fairytale palaces, lush gardens, and breathtaking vistas as we take you on a journey through this UNESCO World Heritage site.

Begin your day with a visit to the iconic Pena Palace, a whimsical masterpiece perched high atop the Sintra mountains. Marvel at its vibrant colors and intricate architecture as you explore the palace grounds and soak in panoramic views of the surrounding countryside.

Next, venture into the mystical realms of Quinta da Regaleira, a sprawling estate shrouded in mystery and intrigue. Lose yourself in its lush gardens, underground tunnels, and enigmatic monuments as you uncover the secrets of this hidden gem.

No trip to Sintra would be complete without a visit to the imposing Moorish Castle, a medieval fortress steeped in history and legend. Climb its ancient walls and towers for sweeping views of the town below, and imagine yourself transported back in time to a bygone era.

Round off your day with a stroll through the historic streets of Sintra, where charming cafes, artisan shops, and quaint boutiques await around every corner. Sample local delicacies, pick up unique souvenirs, and immerse yourself in the town's vibrant culture before bidding farewell to this magical destination.`,
      
      keywords:"Sintra, Sintra in one day, Sintra day trip, Sintra attractions, Sintra sightseeing, Sintra tour, Sintra travel guide, Sintra itinerary, Sintra castle, Pena Palace, Quinta da Regaleira, Moorish Castle, Sintra National Palace, Sintra UNESCO, Sintra blog, Guiders.pt",
        meta: [
        {
          vmid: "description",
          name: "description",
          content:'Discover the enchanting wonders of Sintra in just one day with our expert guide. Uncover the top attractions, hidden gems, and essential experiences to make the most of your visit. From fairytale palaces to breathtaking landscapes, embark on a magical journey through this UNESCO World Heritage site. Let Guiders.pt be your companion as you explore the best of Sintra in a day.'
           },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Best things to see and do in Sintra in a day.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:'Discover the enchanting wonders of Sintra in just one day with our expert guide. Uncover the top attractions, hidden gems, and essential experiences to make the most of your visit. From fairytale palaces to breathtaking landscapes, embark on a magical journey through this UNESCO World Heritage site. Let Guiders.pt be your companion as you explore the best of Sintra in a day.'
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://guiders.pt/blog/best-things-to-see-and-do-in-sintra-in-a-day",
        },
      ],
      link: [{ rel: "canonical", href: "https://guiders.pt/blog/best-things-to-see-and-do-in-sintra-in-a-day" }],
    };
  },
    created() {
        this.getTickets()
    },
    computed: {
        ...mapState({
            tickets: state => state.tickets[TicketsStates.TICKETS]
        }),
    },
    mounted() {
        window.scrollTo(0, 0);
    },

}

</script>


<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

div.container {
    max-width: 805px !important;
}

::v-deep {
    .image img {
        display: block;
        height: 364px;
        width: 100vw;
    }
}
@media (min-width: 1024px) {
    ::v-deep {
.image img {
    height: 470px;
}
    }
}

@media (min-width: 1300px) {
    ::v-deep {
    .image img {
        height: 510px;
    }
}
    }

    @media (min-width: 1400px) {
        ::v-deep {
        .image img {
            height: 572px;
        }
    }
        }

.is-light-blue {
    margin-top: 0.5rem;
    background-color: #ebfbf4;
    padding: 3px 20px 3px 12px;
    border-radius: 32px;
}

figcaption {
    font-size: .8rem;
    color: lightslategrey;
}

.blog {
    font-family: "Poppins", sans-serif !important;
    overflow-x: hidden;
}

.topic--img {
    height: 250px;
    width: -webkit-fill-available;
    border-radius: 12px;
    object-position: 25% 25%;
}

.image--rounded {
    max-width: 125px;
    margin-top: -30px;
    height: 125px;
    border: 5px solid #fff;
    border-radius: 999px;
    z-index: 29;
}


.cover--img {
    margin: -40px;
    max-width: -webkit-fill-available;
}
</style>